<template>
  <div class="form-container step-three" :update="update">
    <q-form @submit="onSubmit" class="q-gutter-md" v-if="!loading">
      <div class="form-header">
        <div>Le projet a été créé avec succès</div>
        <p>Maintenant vous pouvez :</p>
      </div>

      <div class="steps-wrapper">
        <div class="step-item">
          <div class="title">Étape 1</div>
          <img src="../../assets/icons/new-project-file.svg" />
          <p>
            Si nécessaire, vérifier les informations extraites
          </p>
        </div>
        <div class="step-item">
          <div class="title">Étape 2</div>
          <img src="../../assets/icons/new-project-file-plus.svg" />
          <p>
            Créer un ou plusieurs documents pour ce projet à partir de modèles
          </p>
        </div>
        <div class="step-item">
          <div class="title">Étape 3</div>
          <img src="../../assets/icons/new-project-save.svg" />
          <p>Sauvegarder et télécharger les documents que vous avez créés</p>
        </div>
      </div>

      <q-checkbox
        v-model="checkbox"
        label="Ne plus afficher cette fenêtre. La prochaine fois que vous créerez
        un projet, vous serez directement amené à la page principale du projet. "
      />

      <div class="bottom-buttons">
        <q-btn label="Aller à la page du projet" type="submit" />
      </div>
    </q-form>
    <LoadingOrEmpty v-else :loading="loading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'

export default {
  components: { LoadingOrEmpty },
  data() {
    return {
      checkbox: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('users', ['getCurrentUser']),
    update() {
      this.checkCookie()
      return true
    }
  },
  methods: {
    onSubmit() {
      const { projectId } = this.$route.query

      if (this.checkbox) {
        const name =
          this.getCurrentUser.firstName +
          this.getCurrentUser.lastName +
          'Project'
        document.cookie =
          `${name}=${name}; expires=` + 3600 * 1000 * 24 * 365 * 10 // 1 year
      }

      this.$router.push(`/project/${projectId}`)
    },
    async checkCookie() {
      this.loading = true

      const cookie = this.getCookie(
        this.getCurrentUser.firstName + this.getCurrentUser.lastName + 'Project'
      )

      if (cookie) {
        const { projectId } = this.$route.query
        this.$router.push(`/project/${projectId}`)
      }

      this.loading = false
    },
    getCookie(name) {
      const regexp = new RegExp('(?:^|; )' + name + '=([^;]*)')

      let matches = document.cookie.match(regexp)
      return matches ? decodeURIComponent(matches[1]) : undefined
    }
  }
}
</script>
