<template>
  <div class="form-container step-two" :update="update">
    <q-form @submit="onSubmit" class="q-gutter-md">
      <div class="form-header">
        <div>Créer un nouveau projet</div>
        <p>
          Importer les documents sources contenant les informations nécessaires à la réalisation du projet.
        </p>
      </div>
      <div class="file-wrapper">
        <q-file
          v-model="files"
          :hint="getProjectName"
          outlined
          use-chips
          multiple
          append
          accept=".pdf"
          ref="file"
        >
          <template v-slot:label>
            Project description
          </template>
        </q-file>

        <div v-if="!files || !files.length" @click="$refs.file.$el.click()">
          <img src="../../assets/icons/upload.svg" />
          <div>Pas de sources importées</div>
          <p>
            Veuillez choisir des sources au format PDF contenant les informations utiles au projet.
          </p>
          <p>
            Vous pourrez toujours en rajouter plus tard. 
          </p>
          <p>
            Liste des documents pouvant être analysés automatiquement : extraits Kbis, statuts, baux (civils et commerciaux), bilans comptables, liasses fiscales (2050 à 2059-G).
          </p>
        </div>
      </div>

      <q-linear-progress
        size="10px"
        :value="progress"
        v-if="getIsSendingNewProjectDocuments"
      />

      <div class="bottom-buttons" v-if="!getIsSendingNewProjectDocuments">
        <q-btn label="Passer" class="btn-white" type="button" @click="skip" />
        <q-btn
          v-if="!files || !files.length"
          label="Choisir les sources"
          type="button"
          @click="$refs.file.$el.click()"
        />
        <q-btn
          v-if="files && files.length"
          class="btn-white add-materials"
          label="Ajouter d'autres sources"
          type="button"
          @click="$refs.file.$el.click()"
        />

        <q-btn v-if="files && files.length" class="btn-blue" type="submit">
          <div class="text-left">Importer ces sources</div>
          <q-icon right class="upload-icon" />
        </q-btn>
      </div>

      <div class="bottom-buttons" v-if="getIsSendingNewProjectDocuments">
        <q-btn
          label="Stop uploading"
          class="btn-cancel"
          type="button"
          @click="toggleStop(true)"
        />
      </div>
    </q-form>
    <StopModal
      :content="contentStop"
      :is-show.sync="isStop"
      @toggleStop="toggleStop"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StopModal from '@/components/modals/Stop'

export default {
  components: { StopModal },
  // props: {
  //   projectName: {
  //     type: String,
  //     default: "allo"
  //   }
  // },
  data() {
    return {
      files: null,
      progress: 0,
      currentlyUploadedFile: 0,
      contentStop: {
        title: "Arrêter l'importation",
        text: ["Êtes-vous sûr de vouloir arrêter l’importation des sources ?"]
      },
      isStop: false,
      isStopSuccess: false
    }
  },
  computed: {
    ...mapGetters('projects', [
      'getIsSendingNewProjectDocuments',
      'getErrorSendingNewProjectDocuments',
      'getProjectName'
    ]),
    ...mapGetters('users', ['getCurrentUser']),
    update() {
      this.handlerSetIsSendingNewProjectDocuments({ status: false })
      return true
    }
  },
  methods: {
    ...mapActions('projects', [
      'sendNewProjectDocuments',
      'uploadDocumentToS3',
      'handlerSetIsSendingNewProjectDocuments'
    ]),
    async skip() {
      const { projectId } = this.$route.query

      const result = await this.checkCookie()

      if (result) {
        this.$router.push(`/new-project/?projectId=${projectId}&completed=true`)
        this.$emit('setStep', 3)
      }
    },
    checkCookie() {
      const cookie = this.getCookie(
        this.getCurrentUser.firstName + this.getCurrentUser.lastName + 'Project'
      )

      if (cookie) {
        const { projectId } = this.$route.query
        this.$router.push(`/project/${projectId}`)
        return false
      }

      return true
    },
    getCookie(name) {
      const regexp = new RegExp('(?:^|; )' + name + '=([^;]*)')

      let matches = document.cookie.match(regexp)
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    toggleStop(status) {
      if (!status) {
        this.isStopSuccess = true
        this.skip()
      }
      this.isStop = status
    },
    onSubmit() {
      if (this.files) {
        this.progress = 0

        this.files.map(async file => {
          const data = {
            projectId: this.$route.query.projectId,
            documentFileName: file.name,
            file: file
          }

          const s3UrlObject = await this.sendNewProjectDocuments(data)
          await this.uploadDocumentToS3({ s3UrlObject, file })
          await this.uploadedDocumentItem(data.documentFileName)

          this.currentlyUploadedFile += 1
          this.progress =
            ((100 / this.files.length) * this.currentlyUploadedFile) / 100

          if (this.progress === 1) this.skip()
        })
      }
    },
    uploadedDocumentItem(name) {
      if (this.isStopSuccess) return

      const documents = [
        ...document.querySelectorAll('.file-wrapper span.ellipsis')
      ]
      const el = documents.filter(item => {
        return (
          item.textContent === name &&
          !item.parentNode.classList.contains('uploaded')
        )
      })
      el[0].parentNode.classList.add('uploaded')
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  }
}
</script>
