<template>
  <div class="new-project" :update="update">
    <q-page-container>
      <q-page>
        <StepOne v-if="currentStep === 1" @setStep="setStep" />
        <StepTwo v-if="currentStep === 2" @setStep="setStep" />
        <StepThree v-if="currentStep === 3" />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import StepOne from '@/components/new-project/StepOne'
import StepTwo from '@/components/new-project/StepTwo'
import StepThree from '@/components/new-project/StepThree'
import { mapActions } from 'vuex'

export default {
  components: {
    StepThree,
    StepTwo,
    StepOne
  },
  data() {
    return {
      currentStep: 1
    }
  },
  computed: {
    update() {
      this.checkRoute()
      return true
    }
  },
  methods: {
    ...mapActions('projects', ['handlerSetIsSendingNewProjectDocuments']),
    setStep(step, projectName='') {
      this.currentStep = step
      this.projectName = projectName
    },
    checkRoute() {
      if (this.$route.query.completed) {
        this.currentStep = 3
        return
      }
      if (this.$route.query.projectId) {
        this.currentStep = 2
        return
      }
      if (!this.$route.query.projectId && !this.$route.query.completed) {
        this.currentStep = 1
      }
    }
  },
  beforeDestroy() {
    this.handlerSetIsSendingNewProjectDocuments({ status: false })
  }
}
</script>
